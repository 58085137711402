
import { Vue, Component } from "vue-property-decorator";
import { QuotationService } from "@/api/QuotationServiceApi";

@Component
export default class DeleteQuotationRequest extends Vue {
  code: string = "";

  async created() {
    let code = this.$route.query.code as string;
    if (code == null || code.length == 0) {
      await this.$router.push("/");
    }

    this.code = code;
  }

  async removeQuotationRequest() {
    await QuotationService.DeleteQuotationRequest(this.code)
      .then(async () => {
        this.$swal.fire({
          titleText: this.$t(
            "MODAL.QUOTATION_REQUEST_DELETE.SUCCESS"
          ).toString(),
          confirmButtonText: "Ok",
          icon: "success"
        });
      })
      .catch(() => {
        this.$swal.fire({
          titleText: this.$t("MODAL.QUOTATION_REQUEST_DELETE.ERROR").toString(),
          confirmButtonText: "Ok",
          icon: "error"
        });
      });

    await this.$router.push("/");
  }
}
